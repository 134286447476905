.blog {
    color: black;
}

.blog-post {
    max-width: 1600px;
    background: white;
    padding: 50px;
    margin-bottom: 50px;
    box-shadow: 10px 5px 5px lightgray;
}