/* Fonts */
/* Colors */
.bl-main > section:nth-child(3).bl-expand {
  background: #E6E6E6;
  color: black; }

.blog .bl-content {
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px; }

.blog .bl-content p {
  font-size: 1.2em; }

.blog hr:last-child {
  display: none; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Oswald", sans-serif; }

h1, h2 {
  text-transform: uppercase; }

pre, code {
  font-family: "PT Mono", monospace; }

blockquote {
  font-size: 0.9em;
  font-style: italic; }

article h1 {
  font-size: 2.0em;
  font-weight: normal; }

article h2 {
  font-size: 1.8em;
  font-weight: normal; }

pre {
  padding: 3px; }

/*# sourceMappingURL=main.css.map */
